<template>
  <div class="allanswers container-fluid mt-5">

    <a class="btn btn-outline-primary m-3 " @click="getExport">
      Вивантажити в EXCEL
    </a>

    <vue-good-table
        :columns="columns"
        :rows="data"
        theme="polar-bear"
        :pagination-options="{
    enabled: true,
    mode: 'records',
    perPage: 30,
    position: 'top',
    perPageDropdown: [30, 50, 100],
    dropdownAllowAll: false,
    setCurrentPage: 1,
    jumpFirstOrLast : true,
    firstLabel : 'Перша сторінка',
    lastLabel : 'Остання сторінка',
    nextLabel: 'Далі',
    prevLabel: 'Назад',
    rowsPerPageLabel: 'Записів на сторінці',
    pageLabel: 'сторінка', // for 'pages' mode
    allLabel: 'Всі',
    infoFn: (params) => `поточна сторінка: ${params.firstRecordOnPage}`,
  }"
        :search-options="{
          enabled: true,
         placeholder: 'Пошук по таблиці',
        }"
    />
  </div>
</template>

<script>
import axios from 'axios'
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import {mapGetters, mapMutations} from "vuex";
import router from "../router";

export default {
  name: 'Admin',
  data() {
    return {
      data: [],
      columns: [
        {
          label: 'Id',
          field: 'id',
        },
        {
          label: 'П.І.Б.',
          field: 'fullName',
        },
        {
          label: 'П.І.Б. Дитини 1',
          field: 'childFullName1',
        },
        {
          label: 'П.І.Б. Дитини 2',
          field: 'childFullName2',
        },

        {
          label: 'П.І.Б. Дитини 3',
          field: 'childFullName3',
        },
        {
          label: 'Ступінь рідства',
          field: 'userCategory',
        },
        {
          label: 'Місто з якого переїхали',
          field: 'oldSity',
        },
        {
          label: 'Минула школа',
          field: 'oldSchool',
        },
        {
          label: 'Контактний телефон',
          field: 'phone',
        },
        {
          label: 'Де плануєте навчання дитини у 2022-2023 навчальному році?',
          field: 'inDnipro',
        },
        {
          label: 'Нова школа',
          field: 'newSchool',
        },
        {
          label: 'Чи подані документи',
          field: 'docIsAdd',
        },
        {
          label: 'Чи потребує консуультації?',
          field: 'needSupport',
        },
        {
          label: 'Дата заповнення',
          field: 'createdAt',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSXXX',
          dateOutputFormat: 'd-MM-yyyy',
        },
      ],
    }
  },
  methods: {
    async getExport() {
      try {

        let header = {
          'Content-Type': 'application/json',
          'Accept': "application/json"
        };

        let token = localStorage.getItem('token');

        if (token) header.Authorization = `Bearer ${token}`;

        this.link = await axios.get(`${process.env.VUE_APP_API_URL}/answers/export`, {headers: header})

        return location.href = `${process.env.VUE_APP_API_URL}/${this.link.data}`
      } catch
          (err) {
        createToast({
              title: err.response.data.message,
            },
            {
              showCloseButton: 'true',
              swipeClose: 'true',
              toastBackgroundColor: '#e04141',
              showIcon: 'true',
              position: 'top-right',
              type: 'warning',
              transition: 'slide',
            })
      }
    },
    ...mapMutations({setUser: 'user/setUser'})
  },
  async created() {
    if (!this.user.user || this.user.user.role !== 'Admin') {
      createToast({
            title: 'Досуп обмежено',
          },
          {
            showCloseButton: 'true',
            swipeClose: 'true',
            toastBackgroundColor: '#e04141',
            showIcon: 'true',
            position: 'top-right',
            type: 'warning',
            transition: 'slide',
          })
      await router.push('/')
    } else {
      try {
        let header = {
          'Content-Type': 'application/json',
          'Accept': "application/json"
        };

        let token = localStorage.getItem('token');

        if (token) header.Authorization = `Bearer ${token}`;

        this.data = await axios.get(`${process.env.VUE_APP_API_URL}/answers/getall`, {
          withCredentials: true,
          headers: header
        })

        this.data = this.data.data

      } catch (err) {
        createToast({
              title: 'Помилка',
              description: err,
            },
            {
              showCloseButton: 'true',
              swipeClose: 'true',
              toastBackgroundColor: '#e04141',
              showIcon: 'true',
              position: 'top-right',
              type: 'warning',
              transition: 'slide',
            })
      }
    }
  },
  computed: {
    ...mapGetters({user: 'user/user'}),
  }
}
</script>

<style scoped>

.allanswers {
  height: 100%;
  min-height: 100vh;
}

</style>
