<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light" v-if="this.user.user">
    <div class="container-fluid">
<!--      <router-link to="/">-->
<!--        <img src="@/assets/logo.svg" alt="" class="nav__logo">-->
<!--      </router-link>-->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item" v-if="this.user.user && this.user.user.role === 'Admin'">
           <router-link to="Admin" class="m-5">Admin</router-link>
          </li>
        </ul>
        <button class="btn btn-outline-success" @click="logout" v-if="this.user.user">
         Вийти
        </button>
      </div>
    </div>
  </nav>
  <router-view/>

  <section class="mt-5">
    <div class="container-fluid justify-content-center text-center">
      <div class="row align-items-center justify-content-center footer__section">

        <div class="col-lg-2 col-12">
          <img src="@/assets/logo.svg" alt="logo" width="200" class="footer__img">
        </div>

        <div class="col-lg-2 col-12">
          <p class="footer__item text-left2 footer__text">&copy; Всі права захищені</p>
        </div>


        <div class="col-lg-2 col-12">
          <p class="footer__item text-right footer__text">Ми завжди на зв’язку:</p>
        </div>

        <div class="col-lg-1 col-12">
          <a href="tel:+380678769449" class="footer__item">(067) 876-94-49</a>
        </div>

        <div class="col-lg-1 col-12">
          <a href="tel:+380508769449" class="footer__item">(063) 876-94-49</a>
        </div>

        <div class="col-lg-1 col-12">
          <a href="tel:+380508769449" class="footer__item">(050) 876-94-49</a>
        </div>

        <div class="col-lg-3 col-12 text-center">
          <a class="social-icon" href="https://www.facebook.com/kpednipro" target="_blank"><i
              class="bi bi-facebook"></i></a>
          <a class="social-icon" href="https://t.me/kp_ednipro" target="_blank"><i class="bi bi-telegram"></i></a>
          <a class="social-icon" href="https://instagram.com/ednipro" target="_blank"><i class="bi bi-instagram"></i></a>
        </div>


      </div>
    </div>
  </section>
</template>

<script>
import router from "./router";
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'Main',
  methods:{
    logout() {
      this.setUser({});
      localStorage.clear();
      router.push('/')
    },

    ...mapMutations({setUser: 'user/setUser'}),
  },
  computed: {
    ...mapGetters({user: 'user/user'}),
  }
}
</script>

<style>
@font-face {
  font-family: "DniproCity-Regular";
  src: url("./assets/fonts/DniproCity-Regular.otf") format("opentype");
}

@font-face {
  font-family: "DniproCity-Bold";
  src: url("./assets/fonts/DniproCity-Bold.otf") format("opentype");
}

@font-face {
  font-family: "DniproCity-Medium";
  src: url("./assets/fonts/DniproCity-Medium.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased !important;
}

a, a:visited, a:active, a:hover {
  text-decoration: none !important;
  color: black !important;
  cursor: pointer !important;
}

body {
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  height: 100%;
}

.nav__logo {
  width: 150px;
  margin-left: 60px;
  margin-top: 5px;
  margin-bottom: 5px;
}


.footer__section {
  margin-top: 50px;
  height: 100%;
  padding: 20px 0;
}

.social-icon img:hover {
  cursor: pointer;
}

.social-icon {
  font-size: 32px;
  margin: 0 10px;
}

.footer__text {
  margin: 0;
  padding: 0;
}

.text-left2 {
  text-align: left;
}

.text-right {
  text-align: right;
}

@media only screen and (max-width: 767px) {

  .header__text{
    margin: auto !important;
    font-family: DniproCity-Bold, -apple-system, sans-serif;
    text-align: center !important;
    font-size: 24px !important;
    margin-top: 20px !important;
    line-height: 30px !important;
    padding: 20px 10px 20px 10px !important;
  }

  .header__subtext{
    margin-top: 80px !important;
    text-align: center;
    font-size: 20px !important;
    padding: 20px !important;
  }

  .header__image{
    display: none;
  }

  .header__content{
    margin-top: 30px;
    min-height: 100% !important;
  }

  .quiz__form{
    margin-top: 30px !important;
    width: 90% !important;
  }

  .quiz__title{
    margin-top: 70px !important;
  }

  .text-left2{
    margin: 10px 0;
    text-align: center;
  }

  .text-right{
    margin: 5px 0;
    text-align:  center;
  }

  .quiz__title{
    margin-top: 30px;
  }

  .quiz__btn{
    width: 90% !important;
  }

  .header__text{
    margin: auto;
    margin-top: 50px;
    letter-spacing: 0.7px !important;
    width: 80%;
  }

  .header__subtext{
    margin: auto;
    margin-top: 20px !important;
    letter-spacing: 0.7px !important;
    width: 100%;
  }

  .quiz__input-select{
    width: 100% !important;
  }

  .dont-no{
    text-align: left;
    margin: 20px 0;
  }

}
</style>
