<template>
  <div class="container">
    <div class="row header__content align-items-center justify-content-center">
      <div class="col-md-8 col-12">
        <h2 class="header__text text-center">
          Платформа <br /> для батьків дітей шкільного віку, які є внутрішньо переміщеними особами
        </h2>
        <h2 class="header__subtext text-center">
          Місто Дніпро піклується про своїх гостей. <br />
          Якщо Ви є такою особою, заповніть цю анкету.
        </h2>
      </div>
      <div class="col-md-4 col-12">
        <img src="@/assets/map.svg" alt="" class="header__image map">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

.header__content {
  height: 100%;
  min-height: 70vh;
}

.header__subtext{
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  font-size: calc(14px + 10 * (100vw / 1280));
  border: solid 2px #3479FF;
  padding: 20px;
  width: 95%;
  border-radius: 50px;
  letter-spacing: 0.5px;
  line-height: 30px;
}

.header__text {
  margin-left: 10px;
  font-size: calc(18px + 15 * (100vw / 1280));
  font-family: DniproCity-Bold, -apple-system, sans-serif;
  letter-spacing: 0.7px;
  line-height: 40px;
  margin-top: 30px;
  background: #F1D43B;
  padding: 20px 0 20px 20px;
  width: 95%;
  border-radius: 50px;
}

.header__image {
  width: 100%;
}

</style>
