export const userStore = {
    state() {
        return {user: {}}
    },
    mutations: {
        setUser(state, data) {
            state.user = data
        }
    },
    getters: {
        user(state) {
            return state.user
        }
    },
    namespaced: true
}
