<template>
  <div class="container mt-5">
    <h1 class="m-5 FAQ__title">
      Найбільш поширені запитання
      <span></span>
    </h1>
    <div class="accordion mt-5" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseTwo">
            Як визначити заклад освіти для Вашої дитини за місцем проживання у місті Дніпрі?
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
             data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <strong><a href="https://ednipro.dp.ua/rishenya.pdf" target="_blank">1-4 класи додаток 2</a></strong>
            <br>
            <strong><a href="https://ednipro.dp.ua/rishenya.pdf" target="_blank">5-9 класи додаток 1</a></strong>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            Як зарахувати дитину до закладу освіти?
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
             data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <strong>Звернутись до адміністрації обраного Вами або найближчого до місця проживання у місті Дніпрі закладу
              освіти</strong>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingThree">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            Які документи необхідні для зарахування дитини до закладу освіти?
          </button>
        </h2>
        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
             data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <strong>
              <ul>
                <li>заява про зарахування;</li>
                <li>паспорт чи інший документ, що засвідчує особу заявника;</li>
                <li>копія та оригінал свідоцтва про народження дитини або документ, що засвідчує особу;</li>
                <li>копія чи оригінал медичної довідки за формою №086-1/о;</li>
                <li>документ, що підтверджує місце проживання дитини чи батьків або довідка ВПО (за наявності);</li>
                <li>документ про освіту або особова справа учня (за наявності);</li>
              </ul>
            </strong>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="heading-4">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#heading4"
                  aria-expanded="false" aria-controls="collapseTwo">
            Які пільги надаються дітям внутрішньо переміщених осіб у закладах освіти міста Дніпра
          </button>
        </h2>
        <div id="heading4" class="accordion-collapse collapse" aria-labelledby="headingTwo"
             data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <strong>
              <ul>
                <li>безоплатне одноразове харчування (за умови offline навчання);</li>
                <li>безоплатний проїзд у міському електротранспорті та пільговий проізд на маршрутах загального корисування зі знижкою вартосі 25% від установленого тарифу (за наявності учнівського квитка);</li>
              </ul>
            </strong>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="heading-6">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#heading6"
                  aria-expanded="false" aria-controls="collapseTwo">
            Куди звертатися, якщо дитину не беруть до школи?
          </button>
        </h2>
        <div id="heading6" class="accordion-collapse collapse" aria-labelledby="headingTwo"
             data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <strong>
              Якщо ви отримали відмову в зарахуванні дитини до закладу освіти у звʼязку з відсутністю вільних місць,
              необхідно звернутися до управління освіти департаменту гуманітарної політики Дніпровської міської ради (dnepr.uon@gmail.com), де вам
              запропонують перелік закладів освіти, максимально доступних і наближених до місця проживання, а також сприятимуть
              зарахуванню дітей до обраного закладу освіти.
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ"
}
</script>

<style scoped>
li {
  list-style-type: circle;
}

.FAQ__title {
  width: 600px;
  margin: auto !important;
}

.FAQ__title span {
  position: relative;
  transform: rotateY(30deg);
}

.FAQ__title span:after {
  content: '?';
  position: absolute;
  left: 50px;
  width: 100px;
  height: 100px;
}

.FAQ__title span:before {
  content: '';
  position: absolute;
  left: 10px;
  top: -10px;
  width: 100px;
  height: 100px;
  background: url("../assets/figure.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}


</style>
