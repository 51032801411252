<template>
  <div class="container text-center mt-5 login">
    <h1 class="login__text">Авториазція</h1>
    <div class="login__form m-auto">
      <div class="row justify-content-center align-items-center mt-5">
        <input type="text" class="login__input col-12" placeholder="Логін" v-model="login">
        <input type="password" class="login__input col-12" placeholder="Пароль" v-model="password">
        <button class="login__button col-12" @click="loginUser">Увійти</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import {mapMutations} from "vuex";
import router from "../router";

export default {
  name: 'Login',
  data(){
    return{
      login: '',
      password: ''
    }
  },
  methods: {
    async loginUser() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, {
          password: this.password,
          login: this.login,
        }, {withCredentials: true}).then((json) => {
          createToast('Успішно!',
              {
                showCloseButton: 'true',
                swipeClose: 'true',
                toastBackgroundColor: '#41e051',
                showIcon: 'true',
                position: 'top-right',
                type: 'success',
                transition: 'slide',
                timeout: 2000
              })

          this.setUser(json.data)
          window.localStorage.setItem('token', json.data.user.token)
          router.push('/admin')
        })
      } catch (err) {
        createToast({
              title: 'Помилка авторизації',
              description: 'Невірний логін або пароль'
            },
            {
              showCloseButton: 'true',
              swipeClose: 'true',
              toastBackgroundColor: '#e04141',
              showIcon: 'true',
              position: 'top-right',
              type: 'warning',
              transition: 'slide',
              timeout: 3000
            })
      }
    },

    ...mapMutations({setUser: 'user/setUser'})
  },
}
</script>

<style scoped>

.login{
  height: 100%;
  min-height: 100vh;
}

.login__form {
  width: 50%;
}

.login__text{
  margin-top: calc(100%/10);
  font-size: calc(35px + 20 * (100vw / 1280));
}

.login__input {
  height: 50px;
  margin: 10px 0;
  border-radius: 50px;
  background: #F8F8F8;
  border: solid 1px black;
  padding-left: 20px;
  font-size: calc(10px + 7 * (100vw / 1280));
}

.login__input:focus, .login__input:active {
  border: solid 1px black;
  outline: none;
}

.login__button {
  margin-top: 20px;
  width: 250px;
  height: 50px;
  background: #3479FF;
  border-radius: 50px;
  font-size: calc(19px + 5 * (100vw / 1280));
  border: none;
  transition: 0.3s;
  color: white;
}

</style>
