<template>
  <Header/>

  <div class="container-md text-center">
    <h1 class="quiz__title">
      Анкета
    </h1>

    <div class="quiz__form row">
      <div class="col-12">
        <label for="fullName" class="quiz__label">1. Прізвище, імʼя особи, яка
          проходить опитування</label>
        <input type="text" id="fullName" class="quiz__input" v-model="fullName">
      </div>

      <p class="quiz__label mt-3"></p>
      <div class="col-12" v-for="(child, index) in this.childCount">
        <label :for="'childFullNamee' + index.toString()" class="quiz__label" v-if="index === 0">2. Прізвище, імʼя
          дитини (шкільного віку)</label>
        <input type="text" id="childFullNamee" class="quiz__input" v-model="childFullName1" v-if="index === 0">
        <input type="text" id="childFullNamee1" class="quiz__input" v-model="childFullName2" v-else-if="index === 1">
        <input type="text" id="childFullNamee2" class="quiz__input" v-model="childFullName3" v-else-if="index === 2">


        <button class="btn btn-outline-primary rounded-pill mt-2 mb-3" @click="childCount++, questionCout++"
                v-if="child === childCount && childCount!== 3">Додати дитину
        </button>
      </div>

      <div class="col-12">
        <label class="quiz__label">
          3. Ким Ви доводитеся дитині
        </label>
        <div class="col-12 mt-3">
          <select name="" id="userCategoty" class="quiz__input-select" v-model="userCategory">
            <option value="Мати">Мати</option>
            <option value="Батько">Батько</option>
            <option value="Дідусь">Дідусь</option>
            <option value="Бабуся">Бабуся</option>
            <option value="Особа, що замінює батьків">Інший законний представник дитини</option>
          </select>
        </div>
      </div>

      <div class="col-12 mt-3">
        <label for="sityOfLeve" class="quiz__label">4. Назва населеного пункту, з якого Ви переїхали до міста
          Дніпра</label>
        <input type="text" id="sityOfLeve" class="quiz__input" v-model="oldSity">
      </div>

      <div class="col-12 mt-3">
        <label for="oldSchool" class="quiz__label">5. Повна назва закладу освіти, в якому раніше навчалася
          дитина</label>
        <input type="text" id="oldSchool" class="quiz__input" v-model="oldSchool">
      </div>

      <div class="col-12 mt-3">
        <label for="phone" class="quiz__label">6. Контактний номер телефону особи, яка проходить опитування</label>
        <input type="text" id="phone" class="quiz__input" v-model="phone">
      </div>

      <div class="col-12 mt-3">
        <label class="quiz__label">
          7. Де плануєте навчання дитини у 2022-2023 навчальному році?
        </label>
        <div class="col-md-12 col-12 mt-3 text-left">
          <input type="radio" id="checkbox-rect1" name="inSity" value="Місто Дніпро" v-model="inDnipro">
          <label for="checkbox-rect1">Місто Дніпро</label>

          <input type="radio" id="checkbox-rect2" name="inSity" value="Інший регіон України" v-model="inDnipro">
          <label for="checkbox-rect2">Інший регіон України</label>

          <input type="radio" id="checkbox-rect10" name="inSity" value="За межами України" v-model="inDnipro">
          <label for="checkbox-rect10">За межами України</label>

          <input type="radio" id="checkbox-rect11" name="inSity" value="Не визначилися" v-model="inDnipro">
          <label for="checkbox-rect11">Не визначилися</label>
        </div>
      </div>

      <div class="col-12 mt-3 animate__animated animate__fadeIn"
           v-if="inDnipro === 'Не визначилися'">
        <label class="quiz__label">
          8. Чи потребуєте консультації?
        </label>
        <div class="col-md-6 col-12 mt-3 text-left">
          <input type="radio" id="checkbox-rect16" name="needSupport" value="Так" v-model="needSupport">
          <label for="checkbox-rect16">Так</label>

          <input type="radio" id="checkbox-rect17" name="needSupport" value="Ні" v-model="needSupport">
          <label for="checkbox-rect17">Ні</label>
        </div>
      </div>

      <div class="col-12 mt-3" v-if="inDnipro === 'Місто Дніпро'">
        <label class="quiz__label">
          8. Чи обрали Ви заклад освіти, де буде навчатися дитина?
        </label>
        <div class="col-md-12 col-12 mt-3 text-left">
          <input type="radio" id="checkbox-rect12" name="schoolIs" value="Так" v-model="schoolIs">
          <label for="checkbox-rect12">Так</label>

          <input type="radio" id="checkbox-rect13" name="schoolIs" value="Ні" v-model="schoolIs">
          <label for="checkbox-rect13">Ні</label>
        </div>
      </div>

      <div class="col-12 mt-3 animate__animated animate__fadeIn"
           v-if="schoolIs === 'Ні' && inDnipro === 'Місто Дніпро'">
        <label class="quiz__label">
          9. Чи потребуєте консультації?
        </label>
        <div class="col-md-6 col-12 mt-3 text-left">
          <input type="radio" id="checkbox-rect18" name="needSupport2" value="Так" v-model="needSupport">
          <label for="checkbox-rect18">Так</label>

          <input type="radio" id="checkbox-rect19" name="needSupport2" value="Ні" v-model="needSupport">
          <label for="checkbox-rect19">Ні</label>
        </div>
      </div>

      <div class="col-12 mt-3 animate__animated animate__fadeIn"
           v-if="schoolIs === 'Так' && inDnipro === 'Місто Дніпро'">
        <label class="quiz__label">
          9. Зазначте заклад:
        </label>
        <div class="col-md-12 row align-items-center mt-3">
          <div class="col-md-12 col-12">
            <select name="" id="selectSchool" class="quiz__input-select" v-model="newSchool">
              <option :value="school" v-for="school in this.schools">{{ school }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-12 mt-3 animate__animated animate__fadeIn"
           v-if="newSchool !== null && inDnipro === 'Місто Дніпро' && schoolIs !== 'Ні'">
        <label class="quiz__label">
          10. Чи подавали Ви документи для зарахування до закладу освіти?
        </label>
        <div class="col-md-6 col-12 mt-3 text-left">
          <input type="radio" id="checkbox-rect4" name="docIsAdd" value="Так" v-model="docIsAdd">
          <label for="checkbox-rect4">Так</label>

          <input type="radio" id="checkbox-rect5" name="docIsAdd" value="Ні" v-model="docIsAdd">
          <label for="checkbox-rect5">Ні</label>
        </div>
      </div>

      <div class="col-12 mt-3 animate__animated animate__fadeIn"
           v-if="inDnipro === 'Так' && newSchool === 'Ще не визначились' || inDnipro === 'Так' && docIsAdd === 'Ні'">
        <label class="quiz__label">
          11. Чи потребуєте Ви консультації?
        </label>
        <div class="col-md-6 col-12 mt-3 text-left">
          <input type="radio" id="checkbox-rect6" name="needSupport" value="Так" v-model="needSupport">
          <label for="checkbox-rect6">Так</label>

          <input type="radio" id="checkbox-rect7" name="needSupport" value="Ні" v-model="needSupport">
          <label for="checkbox-rect7">Ні</label>
        </div>
      </div>

      <div class="col-md-8 col-12 m-auto mb-5 mt-2">
        <button class="quiz__btn" @click="sendData">
          Завершити опитування
        </button>

        <p class="mt-3" style=" font-size: .875em; color: #6c757d;">Натискаючи на кнопку "Завершити опитування" ви автоматично даєте згоду на обробку персональних
          даних</p>
      </div>
    </div>
  </div>

  <FAQ/>
</template>

<script>

import Header from "../components/Header";
import FAQ from "../components/FAQ";
import axios from "axios";
import {createToast} from "mosha-vue-toastify";

export default {
  name: 'Home',
  components: {
    Header,
    FAQ,
  },
  data() {
    return {
      fullName: '',
      childFullName1: '',
      childFullName2: '',
      childFullName3: '',
      userCategory: '',
      oldSity: '',
      childFullName: '',
      oldSchool: '',
      childCount: 1,
      questionCout: 7,
      phone: '',
      inDnipro: '',
      schoolIs: '',
      newSchool: null,
      docIsAdd: null,
      needSupport: null,
      schools: [
        'КЗО «Гімназія  № 1» ДМР',
        'КЗО «Середня загальноосвітня школа № 1» ДМР',
        'КЗЗСО «Гімназія № 2» ДМР',
        'КЗО «Середня загальноосвітня школа № 2» ДМР',
        'КЗО «Гімназія № 3» ДМР',
        'КЗО «Навчально-виховний комплекс № 4 «середня загальноосвітня школа - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 5» ДМР',
        'КЗО «Середня загальноосвітня школа № 6» ДМР',
        'КЗО «Спеціалізована школа № 7 з поглибленим вивченням іноземних мов» ДМР',
        'КЗО «Середня загальноосвітня школа № 8» ДМР',
        'КЗО «Спеціалізована середня загальноосвітня школа № 9 з поглибленим вивченням англійської мови» ДМР',
        'КЗО «Середня загальноосвітня школа № 10 ім. І.І. Манжури» ДМР',
        'КЗО «Середня загальноосвітня школа № 11» ДМР',
        'КЗО «Навчально-виховний комплекс № 12 «загальноосвітній навчальний заклад І ступеня - гімназія» ДМР',
        'КЗО «Спеціалізована школа № 13» ДМР',
        'КЗО «Середня загальноосвітня школа № 14» ДМР',
        'КЗО «Середня загальноосвітня школа № 15» ДМР',
        'КЗО «Спеціалізована школа І-ІІІ ступенів № 16» ДМР',
        'КЗО «Середня загальноосвітня школа № 17» ДМР',
        'КЗО «Середня загальноосвітня школа № 18» ДМР',
        'КЗО «Середня загальноосвітня школа № 19» ДМР',
        'КЗО «Середня загальноосвітня школа № 20» ДМР',
        'КЗО «Середня загальноосвітня школа № 21» ДМР',
        'КЗО «Спеціалізована середня загальноосвітня школа № 22 з поглибленим вивченням іноземної мови» ДМР',
        'КЗО «Спеціалізована багатопрофільна школа № 23 з поглибленим вивченням англійської мови» ДМР',
        'КЗО «Середня загальноосвітня школа № 24» ДМР',
        'КЗО «Середня загальноосвітня школа № 25» ДМР',
        'КЗО «Середня загальноосвітня школа № 26» ДМР',
        'КЗО «Навчально-виховний комплекс № 27 «школа І-ІІІ ступенів - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Навчально-виховне об\'єднання № 28 «гімназія - школа І ступеня - дошкільний навчальний заклад (ясла-садок) - центр позашкільної роботи» ДМР',
        'КЗО «Середня загальноосвітня школа № 29» ДМР',
        'КЗО «Навчально-виховний комплекс № 30 «Загальноосвітній навчальний заклад І-ІІІ ступенів - дошкільний навчальний заклад (ясла-садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 31» ДМР',
        'КЗО «Середня загальноосвітня школа № 32» ДМР',
        'КЗО «Навчально-виховний комплекс № 33 «Маріїнська багатопрофільна гімназія - загальноосвітній навчальний заклад І ступеня» ДМР',
        'КЗО «Середня загальноосвітня школа № 34» ДМР',
        'КЗО «Середня загальноосвітня школа № 35» ДМР',
        'КЗО «Навчально-виховний комплекс № 36 «Спеціалізована середня загальноосвітня школа техніко-економічного профілю - дошкільний навчальний заклад» ДМР',
        'КЗО «Навчально-виховний комплекс № 37 «Дніпровська гімназія - школа І ступеня - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 39» ДМР',
        'КЗО «Середня загальноосвітня школа № 40» ДМР',
        'КЗО «Навчально-виховний комплекс № 41 «середня загальноосвітня школа вільного розвитку - дошкільний навчальний заклад» ДМР',
        'КЗО «Навчально-виховний комплекс № 42 «школа І-ІІІ ступенів - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 43» ДМР',
        'КЗО «Спеціалізована школа № 44 природничо-медичного профілю» ДМР',
        'КЗО «Середня загальноосвітня школа № 45» ДМР',
        'КЗО «Середня загальноосвітня школа № 46» ДМР',
        'КЗО «Середня загальноосвітня школа № 47 з поглибленим вивченням іноземних мов та фізико-математичного профілю» ДМР',
        'КЗО «Навчально-виховний комплекс № 48 «школа І-ІІІ ступенів - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 49» ДМР',
        'КЗО «Середня загальноосвітня школа № 50» ДМР',
        'КЗО «Середня загальноосвітня школа № 51» ДМР',
        'КЗО«Середня загальноосвітня школа № 52» ДМР',
        'КЗО «Спеціалізована середня загальноосвітня школа № 53 з поглибленим вивченням німецької мови» ДМР',
        'КЗО «Середня загальноосвітня школа № 54» ДМР',
        'КЗО«Спеціалізована школа № 55 інформаційно-технологічного профілю» ДМР',
        'КЗО «Середня загальноосвітня школа № 56» ДМР',
        'КЗО«Навчально-виховний комплекс № 57 «загальноосвітній навчальний заклад І ступеня - гімназія» ДМР',
        'КЗО «Середня загальноосвітня школа № 58» ДМР',
        'КЗО «Навчально-виховний комплекс № 59 «загальноосвітній навчальний заклад - дошкільний навчальний заклад» ДМР',
        'КЗО «Навчально-виховний комплекс № 61 «Загальноосвітній навчальний заклад І-ІІ ступенів - техніко-економічний ліцей» ДМР',
        'КЗО «Середня загальноосвітня школа № 62» ДМР',
        'КЗО «Середня загальноосвітня школа № 63» ДМР',
        'КЗО «Середня загальноосвітня школа № 64» ДМР',
        'КЗО «Навчально-виховний комплекс № 65 «школа І-ІІІ ступенів - дошкільний навчальний заклад (ясла-садок)» ДМР',
        'КЗО «Навчально-виховний комплекс № 66 «Гімназія - початкова школа - дошкільний навчальний заклад» ДМР',
        'КЗО «Спеціалізована школа № 67 еколого-економічного профілю» ДМР',
        'КЗО «Середня загальноосвітня школа № 68» ДМР',
        'КЗО «Середня загальноосвітня школа № 69» ДМР',
        'КЗО «Навчально-виховний комплекс № 70 «середня загальноосвітня школа - дошкільний навчальний заклад (ясла-садок)» ДМР',
        'КЗО «Навчально-виховний комплекс № 71 «спеціалізована школа художньо-архітектурного напрямку - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Навчально-виховний комплекс № 72 «школа І-ІІІ ступенів - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 73» ДМР',
        'КЗО «Середня загальноосвітня школа № 74» ДМР',
        'КЗО «Середня загальноосвітня школа № 75» ДМР',
        'КЗО «Середня загальноосвітня школа № 76» ДМР',
        'КЗО «Середня загальноосвітня школа № 77» ДМР',
        'КЗО «Середня загальноосвітня школа № 78» ДМР',
        'КЗО «Навчально-виховне об\'єднання технічного профілю № 79 «загальноосвітній навчальний заклад - дошкільний навчальний заклад - позашкільний навчальний заклад» ДМР',
        'КЗО «Середня загальноосвітня школа № 80» ДМР',
        'КЗО «Середня загальноосвітня школа № 81» ДМР',
        'КЗО «Середня загальноосвітня школа № 82» ДМР',
        'КЗО «Середня загальноосвітня школа № 83» ДМР',
        'КЗО «Середня загальноосвітня школа № 84» ДМР',
        'КЗО «Середня загальноосвітня школа № 85» ДМР',
        'КЗО «Середня загальноосвітня школа № 86» ДМР',
        'КЗО «Навчально-виховний комплекс № 87 «школа І-ІІІ ступенів - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 88» ДМР',
        'КЗО «Середня загальноосвітня школа № 89» ДМР',
        'КЗО "Спеціальна середня загальноосвітня школа № 90" ДМР',
        'КЗО «Середня загальноосвітня школа № 91» ДМР',
        'КЗО «Навчально-виховний комплекс № 92 «школа І-ІІІ ступенів - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 93» ДМР',
        'КЗО «Середня загальноосвітня школа № 94» ДМР',
        'КЗО «Середня загальноосвітня школа № 96» ДМР',
        'КЗО «Середня загальноосвітня школа № 97 імені П.І. Шкідченка» ДМР',
        'КЗО«Середня загальноосвітня школа № 98» ДМР',
        'КЗО«Навчально-виховний комплекс № 99 «багатопрофільна гімназія - школа І ступеня - дошкільний навчальний заклад» ДМР',
        'КЗО «Навчально-виховний комплекс № 100 «загальноосвітній навчальний заклад І-ІІ ступенів - ліцей» ДМР',
        'КЗО«Середня загальноосвітня школа № 101» ДМР',
        'КЗО «Навчально-виховний комплекс № 102 «середня загальноосвітня школа І-ІІІ ступенів - дошкільний навчальний заклад (ясла-садок) комбінованого типу» ДМР',
        'КЗО «Навчально-виховний комплекс № 103 «школа І-ІІІ ступенів - дошкільний навчальний заклад (ясла-садок)» ДМР',
        'КЗО «Навчально-виховний комплекс № 104 «середня загальноосвітня школа - дошкільний навчальний заклад (ясла - садок)» ДМР',
        'КЗО«Середня загальноосвітня школа № 105» ДМР',
        'КЗО «Навчально-виховний комплекс № 106 «середня загальноосвітня школа - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 107» ДМР',
        'КЗО «Навчально-виховний комплекс № 108 «дошкільний навчальний заклад - загальноосвітній навчальний заклад для дітей з малими та затихаючими формами туберкульозу» ДМР',
        'КЗО «КЗО «Середня загальноосвітня школа № 108» ДМР',
        'КЗО «Навчально-виховне об\'єднання № 109 «загальноосвітній навчальний заклад - дошкільний навчальний заклад - позашкільний навчальний заклад» ДМР',
        'КЗО «Навчально-виховний комплекс № 110 «школа І-ІІІ ступенів - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО«Навчально-виховний комплекс № 111 «спеціалізована школа - дошкільний навчальний заклад» ДМР',
        'КЗО «Середня загальноосвітня школа № 112» ДМР',
        'КЗО «Навчально-виховне об\'єднання № 113 «загальноосвітній навчальний заклад - дошкільний навчальний заклад - позашкільний навчальний заклад» ДМР',
        'КЗО «Середня загальноосвітня школа № 114» ДМР',
        'КЗО«Спеціалізована школа № 115» ДМР',
        'КЗО «Середня загальноосвітня школа № 116» ДМР',
        'КЗО «Середня загальноосвітня школа № 117» ДМР',
        'КЗО «Середня загальноосвітня школа № 118 з поглибленим вивченням англійської та німецької мов і предметів природничо-математичного напрямку» ДМР',
        'КЗО «Середня загальноосвітня школа № 119» ДМР',
        'КЗО «Навчально-виховний комплекс № 120 «Школа І-ІІІ ступенів - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа № 121» ДМР',
        'КЗО «Навчально-виховний комплекс № 122 «загальноосвітній навчальний заклад - дошкільний навчальний заклад» ДМР',
        'КЗО «Середня загальноосвітня школа № 123» ДМР',
        'КЗО «Середня загальноосвітня школа № 124» ДМР',
        'КЗО «Навчально-виховний комплекс № 125 «загальноосвітній навчальний заклад - дошкільний навчальний заклад» ДМР',
        'КЗО «Спеціалізована середня загальноосвітня школа № 126 з поглибленим вивченням французької мови» ДМР',
        'КЗО «Неповна середня загальноосвітня школа № 127» ДМР',
        'КЗО «Середня загальноосвітня школа № 128» ДМР',
        'КЗО «Спеціалізована школа № 129 фізико-математичного профілю» ДМР',
        'КЗО «Навчально-виховний комплекс № 130 «загальноосвітній навчальний заклад І ступеня - гімназія» ДМР',
        'КЗО «Навчально-виховний комплекс № 131 «загальноосвітній навчальний заклад І ступеня - гімназія» ДМР',
        'КЗО «Середня загальноосвітня школа № 132» ДМР',
        'КЗО «Середня загальноосвітня школа № 133» ДМР',
        'КЗО «Спеціалізована школа № 134 гуманістичного навчання та виховання» ДМР',
        'КЗО «Середня загальноосвітня школа № 135» ДМР',
        'КЗО «Навчально-виховне об\'єднання № 136 «класична гімназія ім. Кирила і Мефодія - початкова школа - дошкільний навчальний заклад - валеологічний центр» ДМР',
        'КЗО «Навчально-виховний комплекс № 137 «ліцей - загальноосвітній навчальний заклад» ДМР',
        'КЗО «Навчально-виховний комплекс № 138 «загальноосвітній навчальний заклад І ступеня - гімназія» ДМР',
        'КЗО «Навчально-виховний комплекс № 139 «загальноосвітній навчальний заклад - центр творчості «Дума» ДМР',
        'КЗО «Середня загальноосвітня школа № 140» ДМР',
        'КЗО «Спеціалізована середня загальноосвітня школа № 141» ДМР',
        'КЗЗСО «Ліцей № 142 імені П\'єра де Кубертена» ДМР',
        'КЗО «Середня загальноосвітня школа № 143» ДМР',
        'КЗО «Середня загальноосвітня школа № 143» ДМР',
        'КЗО «Навчально-виховний комплекс № 144 «спеціалізована школа з поглибленим вивченням івриту, історії єврейського народу, єврейських традицій - дошкільний навчальний заклад (дитячий садок)» ДМР',
        'КЗО «Середня загальноосвітня школа I-III ступеня № 147 імені В\'ячеслава Чорновола» ДМР',
        'КЗО «Навчально-виховний комплекс № 148 «Спеціалізована школа - дошкільний навчальний заклад (ясла-садок) «Планета щастя» ДМР',
        'КЗО «Навчально-виховний комплекс «Вальдорфська середня загальноосвітня школа І-ІІІ ступенів - дитячий садок» ДМР',
        'КЗО «Дніпровський ліцей інформаційних технологій при Дніпровському національному університеті імені Олеся Гончара» ДМР',
        'КЗО «Міський юридичний ліцей наукового спрямування при Університеті митної справи та фінансів» ДМР',
        'КЗО «Українсько-Американський ліцей» ДМР',
        'КЗО «Фінансово-економічний ліцей наукового спрямування при Університеті митної справи та фінансів» ДМР',
        'КНЗ «Хіміко-екологічний ліцей» ДМР',
        'КЗО «Ліцей митно-податкової справи з посиленою військово-фізичною підготовкою при Університеті митної справи та фінансів» ДМР',
        'КЗО «Навчально-реабілітаційний центр «Надія» ДМР',
      ],
    }
  },
  methods: {
    async sendData() {
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/answer/create`, {
          fullName: this.fullName,
          childFullName1: this.childFullName1,
          childFullName2: this.childFullName2,
          childFullName3: this.childFullName3,
          userCategory: this.userCategory,
          oldSity: this.oldSity,
          oldSchool: this.oldSchool,
          phone: this.phone,
          inDnipro: this.inDnipro,
          newSchool: this.newSchool,
          docIsAdd: this.docIsAdd,
          needSupport: this.needSupport,
          schoolIs: this.schoolIs,
        }, {withCredentials: true}).then((json) => {
          this.$swal.fire({
            icon: 'success',
            title: 'Дякуємо!',
            text: 'За необхідністі з вами звʼяжуться наші оператори.',
          })
        })

      } catch (err) {
        if (err.message === 'Network Error') {
          createToast({
                title: 'Помилка зʼєднання',
              },
              {
                showCloseButton: 'true',
                swipeClose: 'true',
                toastBackgroundColor: '#e04141',
                showIcon: 'true',
                position: 'top-right',
                type: 'warning',
                transition: 'slide',
              })
        } else {
          let i = 0

          while (i <= err.response.data.length) {

            createToast({
                  title: 'Помилка',
                  description: err.response.data[i].msg
                },
                {
                  showCloseButton: 'true',
                  swipeClose: 'true',
                  toastBackgroundColor: '#e04141',
                  showIcon: 'true',
                  position: 'top-right',
                  type: 'warning',
                  transition: 'slide',
                })

            i++
          }
        }

      }
    }
  }
}
</script>

<style scoped>
.quiz__title {
  font-size: 32px;
  font-family: DniproCity-Medium, -apple-system, sans-serif;
  position: relative;
}

.quiz__title:after {
  content: '!';
  position: absolute;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  background: #F1D43B;
  border-radius: 50px;
}

.quiz__form {
  width: 70%;
  margin: auto;
  margin-top: 50px;
}

.quiz__input {
  width: 100%;
  border: solid 1px #3479FF;
  height: 50px;
  border-radius: 50px;
  margin: 20px 0;
  padding-left: 20px;
  outline: none;
  color: black !important;
}

.quiz__label {
  width: 100%;
  text-align: left !important;
  margin-left: 20px;
  font-size: calc(19px + 5 * (100vw / 1280)) !important;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  position: relative;
  padding-left: 50px;
  margin-right: 20px;
  padding-top: 5px;
  margin-bottom: 20px;
  font: 14px/20px "Open Sans", Arial, sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="radio"]:hover + label:before {
  background: #5FB5F3;
  box-shadow: inset 0px 0px 0px 10px #3479FF;
  border-radius: 50px;
  transition: all 0.3s ease;
}

input[type="radio"] + label:last-child {
  margin-bottom: 0;
}

input[type="radio"] + label:before {
  content: "";
  display: block;
  width: 2.4em;
  height: 2.4em;
  border: solid 1px #3479FF;
  border-radius: 0.7em;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.2s, background 0.2s ease-in-out;
  transition: all 0.2s, background 0.2s ease-in-out;
  background: #fff;
}

input[type="radio"]:checked + label:before {
  width: 2.4em;
  height: 2.4em;
  border: solid 1px #3479FF;
  border-radius: 0.7em;
  background: #3479FF;
  box-shadow: inset 0px 0px 0px 3px #f7f2f2;
}

.quiz__input-select {
  width: 100%;
  margin: auto;
  border: solid 1px #3479FF;
  height: 50px;
  border-radius: 50px;
  appearance: none;
  background: url('../assets/arrow.svg') no-repeat right;
  background-position-x: calc(100% - 20px);
  padding-left: 20px;
  outline: none;
  color: black !important;
}

.quiz__btn {
  margin-top: 30px;
  width: 80%;
  height: 60px;
  background: #3479FF;
  font-family: DniproCity-Regular, -apple-system, sans-serif;
  font-size: calc(20px + 10 * (100vw / 1280));
  border-radius: 50px;
  border: none;
  color: white !important;
}

.text-left {
  text-align: left;
  margin-left: 20px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}
</style>
